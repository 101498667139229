<template>
  <div class="w-100 round-8 bg-gray flex align-center">
    <h5 class="w-35 pl-4 py-3">{{ itemLabel }}</h5>
    <div class="flex align-center">
      <v-icon size="20px">mdi-arrow-right-thin</v-icon>
      <p class="mb-0 ml-4 py-2 pr-4">{{ itemContent }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemLabel: String,
    itemContent: String
  }
}
</script>
<style>
h5{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color:#0C111D
}
p{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0C111D;
}
.w-100{
  width: 100%;
}
.round-8{
  border-radius: 8px;
}
.bg-gray{
  background-color: #F9FAFB;
}
.flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.w-35{
  width: 35%;
  min-width: 35%;
}
</style>